import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import { styled } from '@mui/system';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import theme from '../theme';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: '#ffffff',
	zIndex: 1,
	top: 64,
});

const UpdateCard = styled(Card)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	// justifyContent: 'center',
	position: 'relative',
	// color: theme.palette.primary.contrastText,
	zIndex: 0,
	':after': {
		position: 'absolute',
		display: 'block',
		width: 'calc(100% - 20px)',
		height: 'calc(100% - 20px)',
		content: '" "',
		top: '10px',
		left: '10px',
		// border: '1px solid rgba(0, 0, 0, 0.15)',
		border: '4px solid rgba(255, 255, 255, 0.45)',
		zIndex: -1,
	},
	'.MuiCardHeader-root': {
		transition: 'all 2s ease',
		// position: 'absolute',
	},
	'.MuiCardContent-root': {
		padding: theme.spacing(5),
		// paddingBottom: theme.spacing(2),
	},
	'.filter': {
		position: 'absolute',
		backgroundColor: 'rgba(0,0,0, 0.5)',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		textAlign: 'center;',
		zIndex: '-1',
		transition: 'all 0.5s ease',
	},
	'&:hover': {
		transition: 'all 0.5s ease',
		color: theme.palette.primary.main,
		'& .filter': {
			backgroundColor: 'rgba(255, 255, 255, 0.7)',
			transition: 'all 0.5s ease',
			transform: 'scale(1.2)',
		},
		'.MuiCardHeader-root': {
			// zIndex: 2,
			transition: 'all 2s ease',
			// position: 'relative',
		},
	},
}));

class UpdateListTemplate extends React.Component {
	render() {
		const { data } = this.props;
		const { edges: updates } = data.allMarkdownRemark;

		return (
			<Section
				p={0}
				sx={{
					// px: { sx: 2, md: 6 },
					backgroundColor: 'transparent',
				}}
			>
				<Masonry
					columns={{ xs: 1, sm: 2 }}
					spacing={0}
					sx={{ maxWidth: '1536px' }}
				>
					{updates &&
						updates.map(({ node: update }) => (
							<UpdateCard
								className='card card-with-border'
								data-background='image'
								data-src='assets/img/fashion-1.jpg'
								key={`${update.id}`}
							>
								{update.frontmatter.featuredimage ? (
									<CardHeader
										sx={{
											p: 0,
											width: '100%',
											'.MuiCardHeader-avatar': { width: '100%' },
											'.gatsby-image-wrapper': {
												width: '100%',
												borderRadius: 0,
												zIndex: -1,
											},
										}}
										avatar={
											<PreviewCompatibleImage
												imageInfo={{
													image: update.frontmatter.featuredimage,
													alt: `featured image thumbnail for post ${update.frontmatter.title}`,
													width:
														update.frontmatter.featuredimage.childImageSharp.gatsbyImageData
															.width,
													height:
														update.frontmatter.featuredimage.childImageSharp.gatsbyImageData
															.height,
												}}
											/>
										}
									/>
								) : null}

								<CardContent className='content text-center'>
									<Typography textAlign='center' variant='h3'>
										{update.frontmatter.title}
									</Typography>
									<Typography textAlign='center' variant='body1' className='description'>
										{update.frontmatter.description}
									</Typography>
								</CardContent>
								{update.excerpt ? (
									<CardActions
										className='footer text-center'
										sx={{ display: 'flex', p: 2, pb: 4, pt: 0, justifyContent: 'center' }}
									>
										<Button
											variant='aleign'
											component={Link}
											to={update.fields.slug}
											sx={{
												'&:hover,&:focus,&:active,&.active': {
													color: '#fff',
													backgroundColor: theme.palette.primary.main,
													borderColor: theme.palette.primary.main,
													'&:after, &:before': { background: theme.palette.primary.main },
												},
											}}
										>
											Continue Reading
										</Button>
									</CardActions>
								) : null}
								<Box className='filter'></Box>
							</UpdateCard>
						))}
				</Masonry>
			</Section>
		);
	}
}

UpdateList.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array,
		}),
	}),
};

export default function UpdateList() {
	return (
		<StaticQuery
			query={graphql`
				query UpdateListQuery {
					allMarkdownRemark(
						sort: { order: DESC, fields: [frontmatter___date] }
						filter: { frontmatter: { templateKey: { eq: "updates" } } }
					) {
						edges {
							node {
								excerpt(pruneLength: 200)
								id
								fields {
									slug
								}
								frontmatter {
									title
									templateKey
									description
									date(formatString: "MMMM DD, YYYY")
									featuredpost
									featuredimage {
										childImageSharp {
											gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
										}
									}
								}
							}
						}
					}
				}
			`}
			render={(data, count) => <UpdateListTemplate data={data} count={count} />}
		/>
	);
}
